<template>
  <div class="page">
    <!-- <div v-if="loading" class="circle-box" :style="'background: '+ background +';'">
      <van-circle v-model="currentRate" :rate="rate" :speed="100" :text="text" layer-color="#000" color="#fff" />
    </div> -->
    <!--  v-show="containerShow"  -->
    <div class="swiper-container swiper-bigBox">
      <div class="swiper-wrapper">
        <div :class="'swiper-slide page_' + item.num" v-for="(item, index) in data" :key="index">
          <template v-for="(item1, index1) in data[index].types">
            <div :key="index1">
              <!-- 视频 -->
              <template v-if="item1.type == 3">
                <video
                  id="myVideo"
                  controls
                  preload
                  loop
                  :class="'video page_' + item1.eBookContents[0].page"
                  :src="item1.eBookContents[0].contentUrl"
                ></video>
                <div v-if="iconVideoShow" class="icon-box">
                  <img class="icon_video" :src="icon_video_play" alt="" @click="videoPlay">
                </div>
              </template>
              <template v-else>
                <template
                  v-for="(item2, index2) in data[index].types[index1].eBookContents">
                  <div :key="index2">
                    <!-- 交互：轮播淡入淡出 -->
                    <template v-if="item2.interact == 1">
                      <!-- 首页背景图从上至下按顺序淡入 -->
                      <template v-if="item2.type == 0">
                        <template v-if="item2.interact == 1">
                          <div :class="'bgBox page_' + item2.page">
                            <img  v-for="(item3, index3) in item2.urlList" :key="index3"
                              :class="'bg fade_in page_' + item2.page"
                              :src="item3"
                              alt=""
                              width="100%"
                              height="100%"
                            />
                          </div>
                        </template>
                      </template>
                      <!-- 蒙版图 -->
                      <template v-if="item2.type == 1">
                        <img
                          :class="
                            'masking page_' + item2.page + ' ' + item2.className
                          "
                          :src="item2.contentUrl"
                          alt=""
                          :width="width_PxToPercent(item2.width)"
                          :height="height_PxToPercent(item2.height)"
                        />
                      </template>
                    </template>
                    <!-- 交互：轮播渐变 —— 垂直方向翻转 -->
                    <template v-else-if="item2.interact == 3">
                      <div class="flipBox">
                        <img
                          :src="item2.urlList[0]"
                          alt=""
                          :class="'masking page_' + item2.page + ' ' + item2.className"
                          :width="width_PxToPercent(item2.width)"
                          :height="height_PxToPercent(item2.height)"
                        />
                      </div>
                    </template>
                    <!-- 交互：上下滑动 -->
                    <template v-else-if="item2.interact == 7 || ((item2.page == 9 || item2.page == 16 || item2.page == 25 || item2.page == 27 || item2.page == 30 || item2.page == 37 || item2.page == 39) && item2.interact == 2)">
                      <!-- 用来存储长图文url地址 -->
                      <span class="saveLongImgUrl" :url="item2.contentUrl"></span>
                    </template>
                    <!-- 单张背景图：点击切换 —— 滑动上移 -->
                    <template v-else-if="item2.interact == 8">
                      <div class="bgBox">
                        <img
                          :src="item2.urlList[0]"
                          alt=""
                          :class="'page_' + item2.page + ' ' + item2.className"
                        />
                      </div>
                    </template>
                    <!-- 单张背景图，没有交互 -->
                    <template v-else-if="item2.type == 0">
                      <div class="bgBox">
                        <img
                          :src="item2.contentUrl"
                          alt=""
                          :class="'page_' + item2.page + ' ' + item2.className"
                        />
                      </div>
                    </template>
                    <!-- 单张蒙版图 -->
                    <template v-else>
                      <img
                        :src="item2.contentUrl"
                        alt=""
                        :class="
                          'masking page_' + item2.page + ' ' + item2.className
                        "
                        :width="width_PxToPercent(item2.width)"
                        :height="height_PxToPercent(item2.height)"
                      />
                    </template>
                  </div>
                </template>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- 第5页，点击人物跳转到对应的页面 -->
    <ul class="persons" v-if="pageNum == 5">
      <li @click="toLvShun"></li>
      <li @click="toNingHao"></li>
      <li @click="toHanGeng"></li>
    </ul>
    <!-- 长图文 —— 上下滑动 -->
    <div id="scroll-box" :class="'scroll-box ' + scrollBoxAnimation" v-if="scrollShow">
      <p class="block"></p>
      <div id="scroll-view" class="scroll-view">
        <img
          :src="longImgUrl"
          alt=""
          width="75%"
        />
        <img v-show="arrowShow" class="icon_arrow bottom_to_top" :src="icon_arrow" alt="">
      </div>
      <p class="block"></p>
    </div>
    <!-- 背景音乐 -->
    <div class="music-box">
      <audio id="myAudio" :src="musicUrl" autoplay preload loop></audio>  
      <img v-show="musicShow" class="music-btn" :src="icon_music" alt="">
    </div>
    <!-- 开启弹幕按钮 -->
    <img v-show="(pageNum != 1 && pageNum != 2 && pageNum != 3 && pageNum != 5) && !isShowDanmu" @click="closeOrOpen" data-type="open" class="openDanmuBtn fade_in" :src="icon_danmu_open">
    <!-- 发送弹幕模块 -->
    <div v-show="isShowDanmu" class="danmu fade_in">
      <img @click="closeOrOpen" data-type="close" class="closeDanmuBtn" :src="icon_danmu_off">
      <div class="inputBox">
        <input class="danmuInput" type="text" v-model="danmuVal" placeholder="艺术的边界在哪里？"/>
        <a @click="sendDanmu" href="javascript:void(0);" class="sendBtn">发送</a>
      </div>
    </div>
    <!-- 弹幕显示区域模块 -->
    <div v-if="isShowDanmu" id="danmuview" class="danmuview fade_in">
      <template v-for="(item, index) in danmuData">
        <div :key="index" v-if="item.display" class="danmuText" :style="'animation-duration: ' + item.time + 's; top: '+ item.top +'%; color: '+ item.color+ ';'">
          {{item.text}}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
// 引入weixin-js-sdk
import wx from 'weixin-js-sdk';
import api from "@/utils/api";
import icon_video_play from "@/assets/BaiCha/icon_video_play.png";
import icon_arrow from "@/assets/HanGeng/icon_arrow.png";
import icon_play from "@/assets/BaiCha/icon_music_play.png";
import icon_stop from "@/assets/BaiCha/icon_music_stop.png";
import icon_danmu_open from "@/assets/BaiCha/icon_danmu_open.png";
import icon_danmu_off from "@/assets/BaiCha/icon_danmu_off.png";
// import typewriter_mp3 from "@/assets/BaiCha/typewriter.mp3";



// 弹幕功能
let clickLimit = false;
let vueThis = null;
let danmuList = [];
let i = 0; //用做唯一的wx:key
class Doomm {
  constructor(text, top, time, color){
    this.text = text;
    this.top = top;
    this.time = time;
    this.color = color;
    this.display = true;
    this.id = i++;
    let that = this;
    setTimeout(function(){
      // danmuList.splice(danmuList.indexOf(that), 1); //动画完成，从列表中移除这项
      vueThis.danmuData = danmuList;
    }, this.time*1000);//定时器动画完成后执行。
  }
}
export default {
  name: "HanGeng",
  components: {},
  data() {
    return {
      loading: true,
      containerShow: false,
      background: '',
      currentRate: 0,
      rate: 0,
      data: [],
      pageNum: 1,
      mySwiper: 0,
      longImgUrl: '',
      scrollBoxAnimation: '',
      scrollShow: false,
      arrowShow: false,
      musicShow: false,
      iconVideoShow: true,
      icon_video_play: icon_video_play,
      icon_arrow: icon_arrow,
      icon_music: icon_play,
      musicUrl: '',
      danmuData: [],
      icon_danmu_open: icon_danmu_open,
      icon_danmu_off: icon_danmu_off,
      isShowDanmu: false,
      danmuVal: '',
      // typewriter_mp3: typewriter_mp3,
      audioUrl: '',
    };
  },
  computed: {
    text() {
      return this.currentRate.toFixed(0) + '%';
    },
  },
  methods: {
    // 获取电子刊详情（背景音乐）
    getEbookDetail() {
      const that = this;
      api
        .getEbookDetail({
          id: 12
        })
        .then((res) => {
          if (res.code == 200) {
            that.audioUrl = res.data.audio;
            that.musicUrl = that.audioUrl;
          }
        });
    },
    // 获取电子刊数据 
    getEbookList() {
      const that = this;
      api
        .getEbookList({
          id: 12
        })
        .then((res) => {
          if (res.code == 200) {
            const data = res.data;
            // 处理数据
            for (let i = 0; i < data.length; i++) {
              for (let j = 0; j < data[i].types.length; j++) {
                for (
                  let z = 0;
                  z < data[i].types[j].eBookContents.length;
                  z++
                ) {
                  let item = data[i].types[j].eBookContents[z];
                  // 添加位置className
                  switch (item.position) {
                    case 1:
                      item.className = "leftT";
                      break;
                    case 2:
                      item.className = "leftC";
                      break;
                    case 3:
                      item.className = "leftB";
                      break;
                    case 4:
                      item.className = "centerT";
                      break;
                    case 5:
                      item.className = "centerC";
                      break;
                    case 6:
                      item.className = "centerB";
                      break;
                    case 7:
                      item.className = "rightT";
                      break;
                    case 8:
                      item.className = "rightC";
                      break;
                    case 9:
                      item.className = "rightB";
                      break;
                    case 10:
                      item.className = "bg fade_in_bg";
                      break;
                    default:
                      item.className = "";
                      break;
                  }
                  // 添加交互className
                  switch (item.interact) {
                    case 1:
                      item.className += "";
                      break;
                    case 2:
                      if (item.page == 5) {
                        switch (z) {
                          case 0:
                            item.className += " fade_in";
                            break;
                          case 1:
                            item.className += " fade_in duration2";
                            break;
                          case 2:
                            item.className += " fade_in duration3";
                            break;
                          case 3:
                          case 4:
                          case 5:
                            item.className += " fade_in duration4";
                            break;
                        }
                      }
                      else {
                        item.className += " fade_in";
                      }
                      break;
                    case 3:
                      item.className += " flip_3d";
                      break;
                    case 4:
                      item.className += " right_to_left";
                      break;
                    case 5:
                      item.className += " left_to_right";
                      break;
                    case 6:
                        item.className += " bottom_to_top";
                      break;
                    case 7:
                      item.className += " scroll";
                      break;
                    default:
                      item.className += "";
                      break;
                  }
                }
              }
            }
            that.data = res.data;
            // that.data = res.data.slice(10);
            that.$nextTick(() => {
              // setTimeout(() => {

                // 初始化Swiper
                that.initSwpier();

                // that.background = 'rgba(0, 0, 0, .6)';
                // that.containerShow = true;
              // }, 2000);
              // setTimeout(() => {
              //   that.rate = 100;
              // }, 3000);
              // setTimeout(() => {
              //   that.loading = false;
              //   that.containerShow = true;
              //   that.musicUrl = that.audioUrl;
              // }, 5000);
              
            });
            // 渲染完成之后
            that.$nextTick(() => {
              that.$myVideo = document.getElementById('myVideo');
              if (that.$myVideo === null) { return; }
              // 监听视频播放
              that.$myVideo.addEventListener("play", () => {
                console.log("video is playing");
                // 暂停背景音乐
                that.stop();
              });
              // 监听视频暂停
              that.$myVideo.addEventListener("pause", () => {
                console.log("video is stop");
              });
            });
          } else {
            alert(res.message);
          }
        });
    },
    // 初始化swiper
    initSwpier() {
      const that = this;

      if (that.mySwiper !== 0) {
        that.mySwiper.destroy();
      }
      
      that.mySwiper = new window.Swiper(".swiper-bigBox", {
        // initialSlide: 29, // 默认从第几个显示index
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        effect: "fade", // 切换动画淡入淡出
        fadeEffect: {
          crossFade: true, // 淡出
        },
        // slidesPerView: 1,//每页显示几个
        // spaceBetween: 0,//每个间距是多少
        // loop: true,
        // autoplay: true,
        // autoplay: {
        //   delay: 3000, //时间 毫秒
        //   disableOnInteraction: false, // 用户操作之后是否停止自动轮播默认true
        // },
        on: {
          // 滑动开始
          slideChangeTransitionStart: function() {
            that.longImgUrl = '';
            that.scrollShow = false;
            that.arrowShow = false;
          },
          // 滑动结束
          slideChangeTransitionEnd: function() {
            that.pageNum = this.activeIndex + 1;
            const page = that.pageNum;
            console.log("第" + page + "页");

            // 监听长图文左右滑动事件
            if (page == 5) {
              that.$nextTick(()=>{
                let $persons = document.getElementsByClassName('persons')[0];
                that.srcollBoxTouch($persons);
              });
            }

            // 暂停视频播放
            if (that.$myVideo) {
              that.$myVideo.pause();
            }

            // 是否显示背景音乐按钮
            if (page == 1 || page == 2) {
              that.musicShow = false;
            } else {
              that.musicShow = true;
            }
            // 显示长图文scroll-box
            if (page == 7 || page == 9 || page == 11 || page == 14 || page == 16 || page == 18 || page == 22 || page == 25 || page == 27 || page == 30 || page == 37 || page == 39) {
              let $active = document.getElementsByClassName('swiper-slide-active')[0];
              let $span = $active.getElementsByClassName('saveLongImgUrl')[0];
              let imgUrl = $span.attributes.url.value;
              that.longImgUrl = imgUrl;
              if (page == 9 || page == 16 || page == 25 || page == 27 || page == 30 || page == 37 || page == 39) {
                that.scrollBoxAnimation = 'fade_in';
              } else {
                that.scrollBoxAnimation = 'bottom_to_top';
                that.arrowShow = true;
              }
              that.scrollShow = true;

              // swiper渲染完成
              that.$nextTick(() => {
                // 监听长图文滑动scroll事件，控制粉色箭头是否显示
                let $scrollDom = document.getElementById("scroll-view");
                $scrollDom.onscroll = function(e) {
                  let clientHeight = $scrollDom.clientHeight;
                  let scrollTop = $scrollDom.scrollTop;
                  let scrollHeight = $scrollDom.scrollHeight;
                  // 顶部 —— 显示箭头
                  if (scrollTop == 0) {
                    that.arrowShow = true;
                  }
                  // 底部 —— 隐藏箭头
                  if (scrollHeight - (scrollTop + clientHeight) < 3) {
                    that.arrowShow = false;
                  }
                };
                // 监听长图文左右滑动事件
                let $scrollBox = document.getElementById('scroll-box');
                that.srcollBoxTouch($scrollBox);
              });
            }
            // 先删除class类名
            if (that.pageNum != 7) {
              document.querySelector('.page_7.bg').classList.remove('slide_up');
            }
            if (that.pageNum != 9) {
              document.querySelector('.page_9.bg').classList.remove('slide_up');
            }
            if (that.pageNum != 11) {
              document.querySelector('.page_11.bg').classList.remove('slide_up');
            }
            if (that.pageNum != 14) {
              document.querySelector('.page_14.bg').classList.remove('slide_up');
            }
            if (that.pageNum != 16) {
              document.querySelector('.page_16.bg').classList.remove('slide_up');
            }
            if (that.pageNum != 18) {
              document.querySelector('.page_18.bg').classList.remove('slide_up');
            }
            if (that.pageNum != 22) {
              document.querySelector('.page_22.bg').classList.remove('slide_up');
            }
            if (that.pageNum != 25) {
              document.querySelector('.page_25.bg').classList.remove('slide_up');
            }
            if (that.pageNum != 27) {
              document.querySelector('.page_27.bg').classList.remove('slide_up');
            }
            if (that.pageNum != 30) {
              document.querySelector('.page_30.bg').classList.remove('slide_up');
            }
            if (that.pageNum != 37) {
              document.querySelector('.page_37.bg').classList.remove('slide_up');
            }
            if (that.pageNum != 39) {
              document.querySelector('.page_39.bg').classList.remove('slide_up');
            }
            // 为背景图加class名slide_up
            if (page == 7 || page == 9 || page == 11 || page == 14 || page == 16 || page == 18 || page == 22 || page == 25 || page == 27 || page == 30 || page == 37 || page == 39) {
              let $active = document.getElementsByClassName('swiper-slide-active')[0];
              let $bg = $active.getElementsByClassName('bg')[0];
              $bg.classList.add('slide_up');
            }
          },
          slidePrevTransitionEnd: function(){
            console.log('上一页');
          },
          slideNextTransitionEnd: function(){
            console.log('下一页');
            // 背景音乐
            // if (that.pageNum == 2) {
            //   that.musicUrl = that.audioUrl
            // }
          },
        },
      });
      
    },
    // 第5页，点击人物跳转到对应的页面
    toLvShun() {
      this.mySwiper.slideTo(5, 1000, false);
    },
    toNingHao() {
      this.mySwiper.slideTo(9, 1000, false);
    },
    toHanGeng() {
      this.mySwiper.slideTo(19, 1000, false);
    },
    // 获取弹幕
    getDanmuList() {
      const that = this;
      api
        .getDanmuList({
          ebookId: 10
        })
        .then((res) => {
          if (res.code == 200) {
            for (let i=0; i<res.data.length; i++) {
              const content = res.data[i].content;
              if (content != '') {
                that.bindbt(content, Math.ceil(Math.random()*10 + 4));
              }
            }
          }
        });
    },
    // 发送弹幕
    sendDanmu() {
      const that = this;
      const danmuVal = that.danmuVal;

      if (danmuVal.replace(/(^\s*)|(\s*$)/g, "") == '') {
        // that._showToast('请输入要发表的文字');
        return;
      }

      if (clickLimit) { return; }
      clickLimit = true;

      api
        .sendDanmu({
          ebookId: 12,
          content: danmuVal
        })
        .then((res) => {
          if (res.code == 200) {
            clickLimit = false;
            // 调用弹幕动画
            that.bindbt(danmuVal, 6);
            that.danmuVal = '';
          } else if (res.code == 400) {
              clickLimit = false;
              that._showToast(res.message);
          } else {
              clickLimit = false;
          }
        })
    },
    // 实例化弹幕动画对象
    bindbt(danmuVal, time){
      const that = this;
      danmuList.push(new Doomm(
        danmuVal,
        // Math.ceil(Math.random()*100 - 20),
        Math.ceil((Math.random() * (8 - 2) + 2) * 10.5),
        time,
        that.getRandomColor()
      ));
      
      that.danmuData = danmuList;
    },
    // 弹幕字体随机色
    getRandomColor() {
      let rgb = [];
      for (let i = 0; i < 3; ++i) {
        let color = Math.floor(Math.random() * 256).toString(16);
        color = color.length == 1 ? '0' + color : color;
        rgb.push(color);
      }
      return '#' + rgb.join('');
    },
    // 弹幕去重
    danmuUniq() {
      var danmuData = this.danmuData;
      for (let i=0; i<danmuData.length; i++) {
        danmuData[i].display = false; // 解决频繁关闭开启，导致弹幕显示重复的问题
      }
    },
    // 关闭或开启弹幕
    closeOrOpen: function(e) {
      const type = e.srcElement.dataset.type;
      this.isShowDanmu = type == 'close' ? false : true;
      if (type == 'close') { // 关闭弹幕
        // 弹幕去重
        this.danmuUniq();
      } else { // 开启弹幕
        // 获取弹幕
        this.getDanmuList();
        // 监听弹幕显示区域左右滑动事件
        this.$nextTick(() => {
          let $danmuview = document.getElementById('danmuview');
          this.srcollBoxTouch($danmuview);
        });
      }
    },
    // 根据图片的宽度像素，转换成百分比
    width_PxToPercent(width) {
      // if (width < 828) {
      //   width = (width / 828) * 100 + '%';
      // } else {
      //   width = 'auto';
      // }
      // return width;
    },
    // 根据图片的高度像素，转换成百分比
    height_PxToPercent(height) {
      // if (height > 1334) {
      //   height = 1334;
      //   height = (height / 1334) * 100 + '%';
      // } else {
      //   height = 'auto';
      // }
      // return height;
    },
    // 获取url地址栏参数
    getQueryString(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
      var r = window.location.href.split('?')[1].match(reg);
      if (r != null) {
        return unescape(r[2]);
      }
      return null;
    },
    // 监听其他模块（非swiper）左右滑动事件
    srcollBoxTouch($Dom) {
      const that = this;
      // 手指滑动
      let touchDotX = 0; //X按下时坐标
      let touchDotY = 0; //y按下时坐标
      let interval; //计时器
      let time = 0; //从按下到松开共多少时间*100
      // 滑动开始
      $Dom.addEventListener("touchstart", function (event) {  
        let touch = event.touches[0];
        touchDotX = touch.pageX; // 获取触摸时的原点
        touchDotY = touch.pageY;
        // 使用js计时器记录时间
        interval = setInterval(function() {
          time++;
        }, 100);
      }, false);
      // 滑动结束
      $Dom.addEventListener("touchend", function (event) {  
        let touchMoveX = event.changedTouches[0].pageX;
        let touchMoveY = event.changedTouches[0].pageY;
        let tmX = touchMoveX - touchDotX;
        let tmY = touchMoveY - touchDotY;
        if (time < 20) {
          let absX = Math.abs(tmX);
          let absY = Math.abs(tmY);
          // 左右滑动
          if (absX > 2 * absY) {
            // 左滑
            if (tmX < -20) {
              console.log("左滑==>下一页");
              that.mySwiper.slideNext();
            }
            // 右滑
            if (tmX > 20) {
              console.log("右滑==>上一页");
              that.mySwiper.slidePrev();
            }
          }
          // 上滑动
          if (absY > absX * 2 && tmY < 0) {
            console.log("上滑动=====");
          }
          // 下滑动
          if (absY > absX * 2 && tmY > 0) {
            console.log("下滑动=====");
          }
        }
        clearInterval(interval); // 清除setInterval
        time = 0;
      }, false);
    },
    // 播放视频
    videoPlay() {
      this.iconVideoShow = false;
      this.$myVideo.play();
    },
    // 背景音乐 
    play() { // 播放
      this.$myAudio.play();
      this.$musicBtn.classList.add('play');
      this.$musicBtn.classList.remove('stop');
      this.icon_music = icon_play;
    },
    stop() { // 暂停
      this.$myAudio.pause();
      this.$musicBtn.classList.add('stop');
      this.$musicBtn.classList.remove('play');
      this.icon_music = icon_stop;
    },
    audioAutoPlay() {
      this.play();
      var that = this;
      //控制小喇叭的播放状态
      // this.$myAudio.addEventListener("playing", function() {        
      //   that.$musicBtn.classList.add('play');
      //   that.$musicBtn.classList.remove('stop');
      // });
      // this.$myAudio.addEventListener("pause", function(){
      //   that.$musicBtn.classList.add('stop');
      //   that.$musicBtn.classList.remove('play');
      // });  
      
      // 监听微信环境 
      document.addEventListener("WeixinJSBridgeReady", function () {  
        that.play();
      }, false);
      // 触发播放音乐效果，解决浏览器或者APP自动播放问题
      // document.addEventListener("touchstart", () => {
      //   if(!that.isPlay) {
      //     that.play();
      //     that.isPlay = true;
      //   }
      // }, false);
      // 监听音乐按钮的点击事件-暂停/播放
      this.$musicBtn.addEventListener("click", () => {
        if (this.$myAudio.paused) {
          this.play();
        } else {
          this.stop();
        } 
      }, false);
    },
  },
  created() {
    vueThis = this;
    // 获取电子刊详情（背景音乐）
    this.getEbookDetail();
    // 获取电子刊数据
    this.getEbookList();
  },
  mounted() {
    this.$myAudio = document.getElementById('myAudio');
    this.$musicBtn = document.querySelector('.music-btn');        
    this.audioAutoPlay();
    
    // var ua = navigator.userAgent.toLowerCase();
    // if (ua.match(/MicroMessenger/i) == "micromessenger") {
    //   //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
    //   wx.miniProgram.getEnv((res)=>{
    //     if (res.miniprogram) {
    //       console.log("在小程序里");
    //     } else {
    //       console.log("不在小程序里");
    //     }
    //   })
    // } else {
    //   console.log('不在微信里');
    // }
  },
};
</script>

<style scoped>
.circle-box {
  background: rgba(0, 0, 0, 1);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9;
}

.swiper-container {
  width: 100vw;
  height: 100vh;
}
.swiper-wrapper .swiper-slide {
  width: 100%;
  height: 100%;
  background: #000;
}
.swiper-container.swiper-son,
.swiper-wrapper.swiper-son .swiper-slide {
  pointer-events: none !important;
}
/* 蒙版位置 */
.leftT,
.leftC,
.leftB,
.centerT,
.centerC,
.centerB,
.rightT,
.rightC,
.rightB {
  position: absolute;
  width: 100%;
  height: auto;
}
.leftT {
  left: 0;
  top: 0;
}

.leftC {
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.leftB {
  left: 0;
  bottom: 0;
}

.centerT {
  left: 50%;
  top: 3%;
  transform: translateX(-50%);
}

.centerC {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.centerB {
  left: 0;
  bottom: 3%;
}

.rightT {
  right: 0;
  top: 1%;
}

.rightC {
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.rightB {
  right: 0;
  bottom: 3%;
}

.leftT.page_4 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.leftT.page_5 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.rightB.page_40 {
  right: 0;
  bottom: initial;
  top: 1%;
}

/************* 淡入 *************************************/
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fade_in {
  animation: fadeIn 1.5s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  /* animation: fadeIn 1.5s ease-in; */
}
.duration2 {
  animation-duration: 3s;
}
.duration3 {
  animation-duration: 4.5s;
}
.duration4 {
  animation-duration: 6s;
}
/* 长图文淡入 */
.fade_in.scroll-box { 
  animation-duration: 2.5s;
}
.fade_in.rightB.page_40 {
  animation-duration: 3s;
}

/************* 淡出 *************************************/
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fade_out {
  animation: fadeOut 1.5s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

/************* 从左向右滑入 *************************************/
@keyframes leftToRight {
  0% {
    opacity: 0;
    transform: translate(-1000px, 0);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
.left_to_right {
  animation: leftToRight 2s ease-in-out;
}

@keyframes leftToRightY50 {
  0% {
    opacity: 0;
    transform: translate(-1000px, 0);
  }
  100% {
    opacity: 1;
    transform: translate(0, -50%);
  }
}

@keyframes leftToRightX50Y50 {
  0% {
    opacity: 0;
    transform: translate(-1000px, -50%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

/************* 从右向左滑入 *************************************/
@keyframes rightToLeft {
  0% {
    opacity: 0;
    transform: translate(1000px, 0);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
.right_to_left {
  animation: rightToLeft 2s ease-in-out;
}

@keyframes rightToLeftX50Y50 {
  0% {
    opacity: 0;
    transform: translate(1000px, -50%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

/************* 从下向上滑入 *************************************/
@keyframes bottomToTop {
  0% {
    opacity: 0;
    transform: translate(0, 1000px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
.bottom_to_top {
  animation: bottomToTop 2s ease-in-out;
}

@keyframes bottomToTopX50 {
  0% {
    opacity: 0;
    transform: translate(-50%, 1000px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@keyframes bottomToTopX50Y50 {
  0% {
    opacity: 0;
    transform: translate(-50%, 1000px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

/* 长图文下向上滑入 */
.bottom_to_top.scroll-box { 
  animation-duration: 2s;
}

/************* 从上向下滑入 *************************************/
@keyframes topToBottomX50Y50 {
  0% {
    opacity: 0;
    transform: translate(-50%, -1000px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes topToBottom {
  0% {
    opacity: 0;
    transform: translate(0, -1000px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
.top_to_bottom {
  animation: topToBottom 2s ease-in-out;
}

/************* 3D立体翻转 *************************************/
@keyframes flip3d {
  0% {
    transform: rotateY(-180deg);
  }
  50% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(180deg);
  }
}
.flip_3d {
  animation: 
  fadeIn 1.5s cubic-bezier(0.6, -0.28, 0.735, 0.045), 
  flip3d 1.5s ease infinite 1s;
  /* animation-delay: 3s; */
}



/************* 放大缩小并居中 *************************************/
@keyframes scaleCenterC {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.5, 1.5);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1, 1);
  }
}

@keyframes scaleCenterT {
  0% {
    opacity: 0;
    transform: translateX(-50%) scale(2, 2);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) scale(1, 1);
  }
}

/************* 视频 *************************************************/
.video {
  width: 96%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.icon-box {
  width: 96%;
  height: 30%;
  background: #000;
  position: absolute;
  left: 2%;
  top: 50%;
  transform: translateY(-50%);
}
.icon_video {
  width: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -25px;
  margin-top: -25px;
}
/************* 单张背景图 *************************************/
.bgBox {
  width: 100vw;
  height: 100vh;
  position: relative;
}
.bgBox img {
  width: auto;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
/************* 蒙版图 *************************************************/
.swiper-slide .masking {
  display: none;
}
.swiper-slide.swiper-slide-active .masking {
  display: block;
}
/************* 垂直方向翻转 ******************************************/
.flipBox {
  /* 3D变形 */
  /* transform-style: preserve-3d;
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000; */
}
.flipBox img {
  transform: translate(0, 0);
}
/************* 上下滑动 *************************************************/
.scroll-box {
  width: 100%;
  height: 35%;
  background: #000;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 9;
}
.scroll-view {
  width: 100%;
  height: 80%;
  overflow-y: auto;
}
.block {
  width: 100%;
  height: 10%;
}
.icon_arrow {
  width: 5%;
  position: absolute;
  right: 5%;
}
/************* 背景音乐 *************************************/
.music-box {
  position: fixed;
  top: 3%;
  right: 3%;
  z-index: 1;
}
.music-box .music-btn {
  width: 25px;
}
.music-box .music-btn.play{
  animation: rotate 3s linear infinite;
}
@keyframes rotate{
  0% {
    transform:rotate(0deg);
  }
  100%{
    transform:rotate(360deg);
  }
}

/************* 弹幕 *************************************/
.openDanmuBtn {
  width: 35px;
  position: absolute;
  left: 5%;
  bottom: 3%;
  z-index: 10;
}
.danmu {
  width: 90%;
  padding: 3% 5%;
  background: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
}
.danmu .closeDanmuBtn {
  width: 35px;
}
.danmu .inputBox {
  position: relative;
  width: 86%;
}
.danmu .danmuInput {
  outline: none;
  width: 78%;
  height: 20px;
  padding: 5px 20% 5px 7px;
  background: #ccc;
  border-radius: 6px;
  color: #333;
  font-size: 14px;
}
.danmu .sendBtn {
  width: 16%;
  height: 25px;
  line-height: 25px;
  background: #CC0000;
  border-radius: 6px;
  text-decoration: none;
  text-align: center;
  color: #fff;
  font-size: 14px;
  position: absolute;
  right: 1%;
  top: 4.5px;
}
.danmuview{
  height: 35%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 10%;
  z-index: 999;
  overflow-x: hidden;
}
.danmuText{
  background: rgba(0, 0, 0, .6);
  border-radius: 100px;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  font-size: 15px;
  position: absolute;
  white-space: nowrap;
  animation: rightToLeftDanmu linear infinite;
}
/************* 弹幕从右向左移动 *************************************/
@keyframes rightToLeftDanmu {
  0% {
    left: 100%; 
  }
  100% {
    left: -100%;
  }
}

/************* 背景图滑动上移 *************************************************/
@keyframes slideUp {
  0% {
    /* top: 0; */
    transform: translate(-50%, 10%);
  }
  100% {
    /* top: -10%; */
    transform: translate(-50%, 0);
  }
}
.bgBox img.slide_up {
  top: -10%;
  animation: slideUp 2s ease-in-out;
}

/* 轮播 */
@keyframes lunboAnimation{
    0%   { opacity: 1; }
    10%  { opacity: 1; }
    20%  { opacity: 1; }
    50%  { opacity: 0; }
    100% { opacity: 0; }
}
@keyframes lunboAnimationOne{
  0%   { opacity: .1; }
  10%  { opacity: 1; }
  20%  { opacity: 1; }
  50%  { opacity: 0; }
  100% { opacity: 0; }
}
.lunbo img {
  animation: lunboAnimationOne 8s ease-in-out infinite;
}
.lunbo img:nth-child(1) {
  animation-delay: 0s;
  z-index: 2;
}
.lunbo img:nth-child(2) {
  animation-delay: 4s;
  z-index: 1;
}

.persons {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  display: flex;
}
.persons li {
  flex: 1;
}

</style>